<template>
  <div class="prices" id="prices">
    <h2>{{ pricingTitle }}</h2>

    <div class="prices__flex">
      <Tile
        :number="pricingTileOneTitle"
        :text="pricingTileOneSubtitle"
        :price="pricingTileOnePrice"
        :callout="pricingTileOneDescription"
        :highlight="false"
      />

      <Tile
        :number="pricingTileTwoTitle"
        :text="pricingTileTwoSubtitle"
        :price="pricingTileTwoPrice"
        :callout="pricingTileTwoDescription"
        :highlight="true"
        :pricingTileCallout="pricingTileCallout"
      />

      <Tile
        :number="pricingTileThreeTitle"
        :text="pricingTileThreeSubtitle"
        :price="pricingTileThreePrice"
        :callout="pricingTileThreeDescription"
        :highlight="false"
      />
    </div>

    <button class="prices__button" @click="isExpanded = !isExpanded">
      <p v-if="!isExpanded">{{ seeMoreButton }}</p>
      <p v-if="isExpanded">{{ seeLessButton }}</p>
    </button>

    <div v-if="isExpanded" class="prices-expanded">
      <p>
        {{ pricingTextOne }}
      </p>
      <br />
      <p>
        {{ pricingTextTwo }}
      </p>

      <h3>{{ pricingSubtitleOne }}</h3>

      <div class="prices__flex">
        <Tile :text="pricingTileFourTitle" :price="pricingTileFourPrice" />

        <Tile :text="pricingTileFiveTitle" :price="pricingTileFivePrice" />
      </div>

      <h3>{{ pricingSubtitleTwo }}</h3>

      <div class="prices__flex">
        <Tile
          :text="pricingTileSixTitle"
          :price="pricingTileSixPrice"
          :callout="pricingTileSixDescription"
        />

        <Tile
          :text="pricingTileSevenTitle"
          :price="pricingTileSevenPrice"
          :callout="pricingTileSevenDescription"
        />

        <Tile
          :text="pricingTileEightTitle"
          :price="pricingTileEightPrice"
          :callout="pricingTileEightDescription"
        />
      </div>

      <p>
        {{ pricingTextThree }}
      </p>

      <br />

      <p>
        {{ pricingTextFour }}
      </p>

      <!-- <h3>{{ pricingSubtitleThree }}</h3> -->

      <div class="prices__flex prices__margin">
        <!-- <Tile :text="pricingTileNineTitle" :price="pricingTileNinePrice" />

        <Tile :text="pricingTileTenTitle" :price="pricingTileTenPrice" />

        <Tile :text="pricingTileElevenTitle" :price="pricingTileElevenPrice" /> -->
      </div>
    </div>

    <Tryfree
      :registerPreText="registerPreText"
      :registerTitle="registerTitle"
      :registerPostText="registerPostText"
    />
  </div>
</template>

<script>
import Tile from "@/components/Tile.vue";
import Tryfree from "@/components/Tryfree.vue";

export default {
  components: {
    Tile,
    Tryfree,
  },
  props: {
    isExpanded: String,
    pricingTitle: String,
    seeMoreButton: String,
    seeLessButton: String,
    pricingTextOne: String,
    pricingSubtitleOne: String,
    pricingSubtitleTwo: String,
    pricingTextTwo: String,
    pricingSubtitleThree: String,
    pricingTextThree: String,
    pricingTextFour: String,
    pricingTileOneTitle: String,
    pricingTileOneSubtitle: String,
    pricingTileOnePrice: String,
    pricingTileOneDescription: String,
    pricingTileCallout: String,
    pricingTileTwoTitle: String,
    pricingTileTwoSubtitle: String,
    pricingTileTwoPrice: String,
    pricingTileTwoDescription: String,
    pricingTileThreeTitle: String,
    pricingTileThreeSubtitle: String,
    pricingTileThreePrice: String,
    pricingTileThreeDescription: String,
    pricingTileFourTitle: String,
    pricingTileFourPrice: String,
    pricingTileFiveTitle: String,
    pricingTileFivePrice: String,
    pricingTileSixTitle: String,
    pricingTileSixPrice: String,
    pricingTileSixDescription: String,
    pricingTileSevenTitle: String,
    pricingTileSevenPrice: String,
    pricingTileSevenDescription: String,
    pricingTileEightTitle: String,
    pricingTileEightPrice: String,
    pricingTileEightDescription: String,
    pricingTileNineTitle: String,
    pricingTileNinePrice: String,
    pricingTileTenTitle: String,
    pricingTileTenPrice: String,
    pricingTileElevenTitle: String,
    pricingTileElevenPrice: String,
    registerPreText: String,
    registerTitle: String,
    registerPostText: String,
    foodImage1: String,
    foodImage2: String,
  },
};
</script>

<style lang="scss">
.prices {
  max-width: 850px;
  margin: auto;
  padding-top: 7.5rem;

  @media (min-width: $tablet) {
    margin-top: -15rem;
  }

  @media (max-width: $tablet) {
    padding: 0rem 1.18rem;
    padding-top: 7.5rem;
    margin-top: 3rem;
  }

  h2 {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  &__flex {
    display: flex;
    justify-content: start;
    gap: 2.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: $tablet) {
      gap: 1rem;
    }

    @media (max-width: 870px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__button {
    display: block;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    cursor: pointer;

    p {
      font-weight: 600;
      text-decoration: underline;
      font-size: 1.25rem;
    }
  }

  &__margin {
    margin-bottom: 4rem !important;
  }
}

.prices-expanded {
  & > p,
  h3 {
    text-align: start;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}
</style>
